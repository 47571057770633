import React from 'react';
import { Routes, Route, } from 'react-router-dom';
import SearchProducts from '../screens/SearchProducts';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<SearchProducts />} />
    </Routes>
  )
}

export default Router;