export const toParams = (obj) => {
  let str = '';
  for (let key in obj) {
    if (str !== '') str += '&';
    if (typeof obj[key] === 'boolean') obj[key] = Number(obj[key]);
    str += key + '=' + obj[key];
  }
  return '?' + str;
}

export const toAmount = (x) => parseFloat(Number(x).toFixed(2));
