/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import * as actions from '../store/actions';
import { StoreService } from '../services';
import { toAmount } from '../utils/parse';
import Checkout from './Checkout-Modal';

const ADMIN_ID = 1;

let debounce = null;
let currentScrollPosition = 0;

const SORT = {
  PRICE_ASC: 1,
  PRICE_DESC: 2,
  RECENT: 3,
  ALFA: 4
};

const WHOLESALE = {
  YES: 1,
  NO: 0
}

const DEFAULT_CATEGORY = {id: 0, name: 'Ver todas'};

const SORT_OPTIONS = [
  {value: SORT.ALFA, label: 'Alfabético'},
  {value: SORT.PRICE_DESC, label: 'Mayor precio'},
  {value: SORT.PRICE_ASC, label: 'Menor precio'},
  {value: SORT.RECENT, label: 'Más reciente'},
];

const SearchProducts = () => {
  const { cart, productCategories } = useSelector(state => state);
  const dispatch = useDispatch();

  const cContRef = useRef();
  const hScrollRef = useRef();
  const bScrollLeftRef = useRef();
  const bScrollRightRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [showCart, setShowCart] = useState(false);

  // FILTROS
  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const [showSort, setShowSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(SORT.ALFA)

  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // const [store, setStore] = useState();

  const [pagination, setPagination] = useState({ current_page: 1, last_page: 1, per_page: 30 });
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getSessionProducts();
    getCategories();
    getDispatchTypes();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getProducts();
  }, [selectedCategories, selectedSort, search]);

  const getSessionProducts = async () => {
    if (!cart.length) return;
    const product_ids = cart.map(x => x.id);

    StoreService.getSession({ product_ids })
      .then(({ data }) => {
        const resume = data.products.map(bdProd => {
          const quantity = cart.find(cartProd => cartProd.id === bdProd.id)?.quantity || 1;
          return { ...bdProd, quantity };
        });
        dispatch(actions.fillCart(resume));
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      });
  }

  const getCategories = async () => {
    StoreService.getCategories({ admin_id: ADMIN_ID })
      .then(res => {
        dispatch(actions.fillProductCategories([
          DEFAULT_CATEGORY,
          ...res.data.categories.data
        ]));
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      });
  }

  const getDispatchTypes = async () => {
    StoreService.getDispatchTypes({ admin_id: ADMIN_ID })
      .then(res => {
        const options = res.data.despachos
          .filter(x => Boolean(x.status))
          .map(x => ({ value: x.id, label: x.name }));

          dispatch(actions.fillDispatchTypes(options));
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      });
  }

  const getProducts = async (page = 1) => {
    if (!isLoading) setIsLoading(true);
    if (page === 1 && !!products.length) setProducts([]);

    StoreService.getProducts({
      admin_id: ADMIN_ID,
      search,
      category_filter: !!selectedCategories.length ? selectedCategories : null,
      sort_by: selectedSort || null,
    }, { page })
      .then(res => {
        const prods = res.data.products.data.map(x => ({ ...x, quantity: 1 }));
        
        let _products = page > 1 ? [...products, ...prods] : prods;

        _products.map((item) => {
          const in_cart = cart.find(i => i.id == item.id);
          if (in_cart) {
            item.sale_price = in_cart?.sale_price;
          }
          return item;
        });

        setProducts(_products);

        setPagination({
          current_page: res.data.products.current_page,
          last_page: res.data.products.last_page,
          per_page: res.data.products.per_page,
        });
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      })
      .finally(() => setIsLoading(false));
  }

  const selectCategory = (category) => {
    let _selectedCategories = [];

    if (category === DEFAULT_CATEGORY.id) {
      if (!selectedCategories.length) return;

    } else if (selectedCategories.includes(category)) {
      _selectedCategories = selectedCategories.filter(x => x !== category);

    } else {
      _selectedCategories = [...selectedCategories, category];
    }

    setSelectedCategories(_selectedCategories);
  }

  const addToCart = (product) => {
    const isSelected = Boolean(cart.find(x => x.id === product.id));

    if (isSelected) {
      dispatch(actions.removeFromCart(product));
      let _products = [...products];
      const index = _products.findIndex(i => i.id == product.id);
      if (index != -1) {
        _products[index].sale_price = null;
      }
      setProducts(_products);
    }
    else {
      dispatch(actions.addToCart(product));
    }
  }

  function scrollHorizontally(val) {
    let scrollAmount = hScrollRef.current.offsetWidth - 50;
    let maxScroll = -cContRef.current.offsetWidth + hScrollRef.current.offsetWidth + 8;

    currentScrollPosition += (val * scrollAmount);

    if (currentScrollPosition > 0) {
      currentScrollPosition = 0
      bScrollLeftRef.current.style.opacity = '0';
    } else {
      bScrollLeftRef.current.style.opacity = '1';
    }

    if (currentScrollPosition <= maxScroll) {
      currentScrollPosition = maxScroll;
      bScrollRightRef.current.style.opacity = '0';
    } else {
      bScrollRightRef.current.style.opacity = '1';
    }

    cContRef.current.style.marginLeft = currentScrollPosition + 'px';
  }

  return (
    <>
      <section className="relative w-full max-w-2xl pt-4 pb-16 mx-auto">

        <div className="fixed w-full top-0 max-w-2xl mx-auto pt-4 left-0 right-0 bg-primary z-10">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} className="max-w-xs m-auto mb-4" alt="Logo" />

          {/* BÚSQUEDA */}
          <div className="px-2">
            <div className="relative max-w-md m-auto">
              <input
                type="text"
                placeholder="Nombre o código"
                className="w-full py-2 pl-4 text-sm text-gray-700 rounded-full focus:outline-none focus:border-secondary focus:ring-secondary bg-white"
                value={searchInput}
                onChange={v => {
                  setSearchInput(v.target.value);

                  if (debounce) clearTimeout(debounce);
                  debounce = setTimeout(() => {
                    setSearch(v.target.value);
                  }, 1000);
                }}
              />

              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <svg className="w-5 h-5 text-primary" viewBox="0 0 24 24" fill="none">
                  <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </span>
            </div>
          </div>

          <div className="flex flex-row justify-center items-center text-xs my-4">
            <div className="flex flex-row items-center text-[#E8C221]">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="text-white ml-1 mr-4">8:00 am - 5:00 pm</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span className="text-white ml-1">Distrito Capital, Caracas</span>
            </div>
          </div>

          {/* CATEGORIAS */}
          <div
            id="horizontal-scroll"
            className="flex items-center whitespace-nowrap overflow-x-scroll hide-scroll-bar mb-2 h-10"
            ref={hScrollRef}
          >
            <button
              onClick={() => scrollHorizontally(1)}
              className="absolute left-0 bg-white/70 px-2 py-2 hidden md:flex ease-in-out duration-300"
              style={{opacity: 0}}
              type="button"
              ref={bScrollLeftRef}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </button>

            <button
              onClick={() => scrollHorizontally(-1)}
              className="absolute right-0 bg-white/70 px-2 py-2 hidden md:flex ease-in-out duration-300"
              type="button"
              ref={bScrollRightRef}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>

            <div id="categories-container" className="my-0 px-2 ease-out duration-1000" ref={cContRef}>
              {productCategories.map(x => (
                <button
                  key={x.id}
                  type="button"
                  className={
                    'category-pill' +
                    ((x.id === DEFAULT_CATEGORY.id && !selectedCategories.length) || selectedCategories.includes(x.id) ? ' category-pill-selected' : '')}
                  onClick={() => selectCategory(x.id)}
                >
                  {x.name}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="h-60"></div>

        {/* FILTROS */}
        <div className="flex flex-row justify-between mx-2 my-2">
          <button
            onClick={() => setShowSort(!showSort)}
            className="flex items-center cursor-pointer p-2 tracking-wide text-white bg-primary rounded-md focus:outline-none"
          >
            <svg className="w-6 h-6" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12 16.54L19.37 10.8L21 12.07L12 19.07L3 12.07L4.62 10.81L12 16.54M12 14L3 7L12 0L21 7L12 14M12 2.53L6.26 7L12 11.47L17.74 7L12 2.53M12 21.47L19.37 15.73L21 17L12 24L3 17L4.62 15.74L12 21.47" />
            </svg>

            <span className="text-xs mx-2 text-white">Ordenar por</span>
          </button>
        </div>

        {/* PRODUCTOS */}
        <div className="mx-2">
          {products.map((product,index) => {
            const isSelected = Boolean(cart.find(x => x.id === product.id));

            return (
              <Product
                key={`product-${product.id}`}
                product={product}
                isSelected={isSelected}
                addToCart={() => addToCart(product)}
                index={ index }
                changePrice={ (index,price) => {
                  let _products = [...products];
                  _products[index].sale_price = price;
                  setProducts(_products);
                } }
              />
            )
          })}

          {isLoading && (
            <div className="flex justify-center mt-4">
              <div role="status">
                <svg aria-hidden="true" className="mr-2 w-6 h-6 text-gray-200 animate-spin fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                </svg>
                <span className="sr-only">Cargando...</span>
              </div>
            </div>
          )}

          {(!isLoading && !products.length) && (
            <div className="flex justify-center mt-10">
              <span className="text-sm">No se encontraron productos</span>
            </div>
          )}

          {(!isLoading && pagination.current_page < pagination.last_page) && (
            <div className="flex justify-center mt-4">
              <button
                onClick={() => getProducts(pagination.current_page + 1)}
                type="button"
                className="focus:outline-none text-gray-900 bg-secondary hover:bg-yellow-400 focus:ring-4 font-medium rounded-md text-sm px-5 py-1.5"
              >Cargar más</button>
            </div>
          )}
        </div>
      </section>

      {/* SIDEBAR - ORDENAR POR */}
      <SidebarOrder
        show={showSort}
        selected={selectedSort}
        onSelect={setSelectedSort}
        onClose={() => setShowSort(false)}
      />

      {/* SIDEBAR - CATEGORÍAS */}
      <SidebarCategories
        show={showCategories}
        selected={selectedCategories}
        categories={productCategories}
        onSelect={selectCategory}
        onClose={() => setShowCategories(false)}
      />

      {!!cart.length && <CartResume onClick={() => {
        const product = products.find(i => {
          const isSelected = Boolean(cart.find(x => x.id === i.id));
          return isSelected && !i.sale_price;
        });
        if (product) {
          toast.error(`Lo sentimos, el precio del producto ${ product?.name } no es válido`);
          return;
        }
        setShowCart(true);
      }} />}

      {showCart && <Checkout onClose={() => setShowCart(false)} />}
    </>
  )
}

const Product = ({ product, isSelected, addToCart, index, changePrice }) => {
  return (
    <div className="flex flex-row justify-between items-center py-3 border-b border-b-gray-200">
      <div className="flex justify-between">
        <div className="flex-none lg:w-32 lg:h-32 w-20 h-20 rounded overflow-hidden">
          <img
            className={'lg:w-32 lg:h-32 w-20 h-20 object-cover text-xs opacity-100'}
            src={process.env.REACT_APP_STORAGE + product.photo?.file}
            alt={product.name}
          />
        </div>

        <div className="mx-2 flex flex-col text-gray-800 my-auto">
          <span className="flex h-10 overflow-hidden text-sm text-ellipsis">{product.name}</span>

          <div className="container-multiples-prices">
            <div style={{ flex: .5 }}>
              <div className={ `container-multiple-price ${ product.sale_price == product.original_price ? 'active' : '' }` } onClick={ () => {
                if (product.sale_price == product.original_price && !isSelected) {
                  changePrice(index,null);
                }
                else {
                  changePrice(index,product.original_price);
                }
              } }>
                <p className="label">Precio Detal</p>
                <p className="price">${ toAmount(product.original_price) }</p>
              </div>
            </div>
            {
              product.wholesale == WHOLESALE.YES && (
                <div style={{ flex: .5 }}>
                  <div className={ `container-multiple-price ${ product.sale_price == product.wholesale_price ? 'active' : '' }` } onClick={ () => {
                    if (product.sale_price == product.wholesale_price && !isSelected) {
                      changePrice(index,null);
                    }
                    else {
                      changePrice(index,product.wholesale_price);
                    }
                  } }>
                    <p className="label">Precio Mayor</p>
                    <p className="price">${ toAmount(product.wholesale_price) }</p>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>

      {/*<span className="text-2xl mt-3 text-primary font-bold">${toAmount(product.sale_price)}</span>*/}
      <div className="container-prices">
        {
          product.sale_price != null && (
            <div className="inline-flex items-center">
              {isSelected
                ? <ButtonAdded onClick={() => addToCart()} />
                : <ButtonAdd onClick={() => addToCart()} />
              }
            </div>
          )
        }

        {/*<input
          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary block w-full p-2.5"
          placeholder="Precio"
          value={product.sale_price}
          onChange={e => {
            const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
            if (regex.test(e.target.value) || e.target.value == '') {
              changePrice(index,e.target.value);
            }
          }}
        />
        <select style={{
          border: '1px solid #e0e0e0',
          maxWidth: '150px',
          borderRadius: '30px'
        }} onChange={e => {
          changePrice(index,e.target.value);
          e.target.value = "";
        }} className="w-full py-2 pl-4 text-sm text-gray-700 rounded-full focus:outline-none focus:border-secondary focus:ring-secondary bg-white">
          <option value="">Seleccionar Precio</option>
          <option value={ product.original_price }>${ toAmount(product.original_price) }</option>
          {
            product.wholesale == WHOLESALE.YES && (
              <option value={ product.wholesale_price }>${ toAmount(product.wholesale_price) } (Al mayor)</option>
            )
          }
          {
            product.prices?.map((item) => {
              return (
                <option value={ item.price }>${ toAmount(item.price) }</option>
              )
            })
          }
        </select>*/}
      </div>
    </div>
  )
}

const ButtonAdd = ({ onClick }) => (
  <button
    onClick={() => onClick()}
    className="flex justify-between px-4 py-1.5 text-primary bg-[#F2F2F2] rounded-full focus:outline-none border border-primary"
  >
    <span className="text-xs">Agregar</span>
  </button>
)

const ButtonAdded = ({ onClick }) => (
  <button
    onClick={() => onClick()}
    className="flex justify-between px-1 py-1.5 text-primary rounded-full focus:outline-none border category-pill-selected"
  >
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <span className="mx-1 text-xs">Agregado</span>
  </button>
)

const SidebarOrder = ({ show, selected, onSelect, onClose }) => (
  <Transition.Root show={show} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => onClose()}>
      <div className="absolute inset-0 overflow-hidden">
        <Dialog.Overlay className="absolute inset-0" />

        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="pointer-events-auto w-screen max-w-2xl">
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900"> Ordenar por </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => onClose()}
                        >
                          <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="py-4 overflow-y-auto">
                    <ul className="space-y-2 mx-2">
                      {SORT_OPTIONS.map(x => (
                        <li
                          key={`modal-sort-${x.value}`}
                          onClick={() => {
                            onSelect(x.value);
                            onClose();
                          }}
                        >
                          <div className={
                            'flex items-center cursor-pointer p-2 text-base font-normal text-gray-500 rounded-lg hover:bg-gray-100' +
                            (selected === x.value ? ' bg-gray-200' : '')
                          }>
                            <span className="ml-3">{x.label}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                    onClick={() => onClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
)

const SidebarCategories = ({ show, selected, categories, onSelect, onClose }) => (
  <Transition.Root show={show} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => onClose()}>
      <div className="absolute inset-0 overflow-hidden">
        <Dialog.Overlay className="absolute inset-0" />

        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="pointer-events-auto w-screen max-w-2xl">
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900"> Categorías </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => onClose()}
                        >
                          <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="py-4 overflow-y-auto">
                    <ul className="space-y-2 mx-2">
                      {categories.map(x => (
                        <li
                          key={`modal-categories-${x.id}`}
                          onClick={() => onSelect(x.id)}
                        >
                          <div className="flex items-center cursor-pointer p-2 text-base font-normal text-gray-500">
                            <div className="flex items-center">
                              <input
                                checked={(x.id === DEFAULT_CATEGORY.id && !selected.length) || selected.includes(x.id)}
                                type="checkbox"
                                value=""
                                className="w-4 h-4 text-secondary bg-gray-100 rounded border-gray-300"
                                onChange={() => {}}
                              />
                              <label htmlFor="checkbox-1" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{x.name}</label>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                    onClick={() => onClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
)

const CartResume = ({ onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      className="fixed w-full bottom-2 px-5 max-w-2xl mx-auto left-0 right-0 cursor-pointer"
    >
      <div className="flex flex-row justify-between items-center bg-secondary py-2 px-4 rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-primary">
          <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
        </svg>
        <span className="text-primary text-lg font-medium">Ver pedido</span>
        <span className="w-6 h-6"></span>
      </div>
    </div>
  );
}

export default SearchProducts;