const initialState = [];

const dispatchTypes = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'FILL_DISPATCH_TYPES':
      return payload;

    default:
      return state;
  }
}

export default dispatchTypes;