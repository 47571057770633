const initialState = [];

const productCategories = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'FILL_PRODUCT_CATEGORIES':
      return payload;

    default:
      return state;
  }
}

export default productCategories;