import api from '../utils/api';
import { toParams } from '../utils/parse';

export const getSession = (params) => {
  return api.post('store/cart', params);
}

export const getCategories = (params) => {
  const filter = !!params ? toParams(params) : '';
  return api.post(`store/categories${filter}`);
}

export const getDispatchTypes = (params) => {
  return api.post('admin/public-store/despachos', params);
}

export const getProducts = (params, pagination) => {
  const filter = !!pagination ? toParams(pagination) : '';
  return api.post(`store/products${filter}`, params);
}

export const confirm = (params) => {
  return api.post('store/cart/confirm', params);
}

export const save = (params) => {
  return api.post('store/cart/save', params);
}
