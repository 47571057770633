const initialState = [];

const cart = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'FILL_CART':
      return payload;

    case 'ADD_TO_CART':
      return [ ...state, payload ];

    case 'REMOVE_FROM_CART':
      return state.filter(x => x.id !== payload.id);

    case 'UPDATE_CART_PRODUCT':
      const idx = state.findIndex(x => x.id === payload.id);
      const all = [ ...state ];

      all.splice(idx, 1, payload);

      return all;

    case 'CLEAR_CART':
      return [];

    default:
      return state;
  }
}

export default cart;